a:link {
    text-decoration: none;
    scroll-behavior: smooth;
}

.hidden-scroll-bar::-webkit-scrollbar {
    display: none;
}

.hidden-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.shop-not-active {
    filter: grayscale(1);
}

.css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.css-13cymwt-control {
    border: none !important;
    box-shadow: none !important;
}


.css-t3ipsp-control {
    border: none !important;
    box-shadow: none !important;
}

.css-13cymwt-control:hover,
.css-13cymwt-control:active,
.css-13cymwt-control:focus {
    border: none !important;
    box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
    width: 0 !important;
}

.set-loaction-input {
    width: 100%;
}

.set-location-searchbar {
    background-color: #fff;
    border-radius: 15px;
}

.setlocation-address-type {
    background-color: #fff;
    border-radius: 15px;
}

.location div.css-1fdsijx-ValueContainer {
    background: linear-gradient(98.81deg, #e9e9e9 -0.82%, #dddddd) !important;
    border-radius: 8px;
    padding: 5px 8px !important;
}

.setlocation div.css-1fdsijx-ValueContainer {
    background: #fff !important;
    border-radius: 8px;
    padding: 2px 8px !important;
}

.search-input-location {
    background: #fff !important;
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem;
}

.search-input-location:hover,
.search-input-location:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    padding: 0.5rem;
}

.set-location-btn {
    background: linear-gradient(98.81deg, #538a37 -0.82%, #7ac356) !important;
    box-shadow: 0px 0px 50px rgba(90, 108, 234, 0.07);
    border-radius: 15px;
    border: none;
    color: #fff;
    width: 100%;
}

.set-current-location-btn {
    border-radius: 15px;
    background-color: #7ac356;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
}

.center-marker {
    position: absolute;
    top: 34%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
}

.center-marker-full {
    position: absolute;
    top: 48%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
}

.center-marker-pulse::after {
    position: absolute;
    content: "";
    top: 29.3%;
    left: 50.7%;
    height: 140px;
    width: 140px;
}


.theme-btn {
    background-color: #7ac356 !important;
    color: #ffffff;
    font-weight: 500;
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    border-radius: 8px;
}

.set-location-address-btn {
    background-color: #fff;
    border-radius: 11px;
    border: 1px solid #5fa13e;
    color: #7ac356;
    width: 30%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.set-location-address-btn-active {
    background: linear-gradient(98.81deg, #5fa13e -0.82%, #7ac356 101.53%);
    border-radius: 11px;
    border: none;
    color: #fff;
    width: 30%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-select-tabs {
    color: #000;
    font-size: 15px;
    font-weight: bold;
    width: 30vw;
    background-color: #e3e3e3;
    display: flex;
    justify-content: center;
    padding: 10px;

}

.home-select-tabs-active {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    width: 30vw;
    background-color: #7ac356;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.home-item-remove {
    padding: 5px;
    color: #7ac356;
    border: 1px solid #7ac356;
    font-size: 12px;
    border-radius: 100px;
    font-weight: 650;
}

.home-item-add {
    padding: 5px;
    color: #7ac356;
    border: 1px solid #7ac356;
    font-size: 12px;
    border-radius: 100px;
    font-weight: 650;
}

.get-start-button {
    border: none;
    border-radius: 10px;
    width: 100%;
    padding: 14px;
    background-color: #7ac356 !important;
    color: #fff;
    font-weight: 700;
}

.cart-tip-card {
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.11);
    border-radius: 22px;
}

.cart-tip {
    border: 1px solid #32bab3;
    border-radius: 10px;
    color: #32bab3;
    background-color: white;
    display: flex;
    align-items: center;
}

.cart-tip-active {
    border: 1px solid #32bab3;
    border-radius: 10px;
    color: #fff;
    background-color: #32bab3;
    display: flex;
    align-items: center;
}

.instant-type {
    padding: 0.6rem 0.8rem;
    background-color: #7ac356;
    border-radius: 0.6rem;
    font-weight: 600;
    color: #fff;
    width: 47%;
    text-align: center;
}

.schedule-type {
    border: 1px solid #7ac356;
    padding: 0.6rem 0.8rem;
    border-radius: 0.6rem;
    font-weight: 600;
    width: 47%;
    color: #7ac356;
    text-align: center;
}

.payment-type {
    padding: 0.6rem 0.8rem;
    background-color: #5563ff;
    border-radius: 0.6rem;
    font-weight: 600;
    color: #fff;
    width: 47%;
    text-align: center;
}


.fixed-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 14px;
}

.order-success-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.order-success-text {
    font-weight: 600;
    font-size: 20px;
    margin-top: 12px;
}


.header-panel-lg .panel span {
    color: rgba(225, 225, 225, 1);
    background: rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 100%;
    width: 34px;
    height: 34px;
    backdrop-filter: blur(3px);
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 100%;
        padding: 1px;
        background: linear-gradient(128.66deg, rgba(var(--white), 1) -1.63%, rgba(var(--white), 0) 92.16%);
        -webkit-mask: linear-gradient(rgba(var(--white), 1) 0 0) content-box, linear-gradient(rgba(var(--white), 1) 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: 1;
    }
}

.restaurants-cafe-box {
    background-color: #fff;
    /* box-shadow: 0px 0px 20px #00000020; */
    border-radius: 14px;
}

.schedulecards {
    background-color: #f8f9fa;
    border-radius: 10px;
    height: 60px;
    width: 5rem;
    padding: 1vw;
    border: 1px solid #7ac356;
    text-align: center;
}

.send-otp-button {
    border: none;
    border-radius: 10px;
    width: 100% !important;
    padding: 14px;
    background-color: #7ac356 !important;
    color: #fff;
    font-weight: 600;
    font-size: large;
}

.food-brands {
    padding: 0px !important;
}

.applied-coupon {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #7ac356;
    background-color: #7ac35620;
    border-radius: 10px;
}

.cart-item-remove-round-btn {
    color: #fff;
    padding: 5px 10px;
    background-color: #7ac356;
    border-radius: 8px;
    font-weight: 600;
}

.cart-coupon-text {
    color: #7ac356;
    font-weight: 600;
}

.error-message-coupon {
    color: #fff;
    font-size: 13px;
    font-weight: 550;
    padding: 8px 14px;
    background-color: rgb(24, 24, 24);
    border-radius: 10px;

}

.order-tracking .tracking-place li::after {
    left: 84px !important;
}

.color-5 {
    color: #A96224 !important;
}

.step-5 {
    background: #A9622430;
}

.tracking-place .active-danger span::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #e22f2f !important;
    border-radius: 100%;
}

.track-order-btn {
    padding: 2px 8px;
    color: #fff;
    background-color: #7ac356;
    font-weight: 500;
    border-radius: 6px;
    width: 100%;
}

/* 
.vertical-product-box {
    align-items: start !important;
} */

.order-box .vertical-box-img {
    height: auto !important;
}

.red-strike {
    position: relative;
    color: rgb(99, 99, 99);
    font-weight: 600;
}

.red-strike-line {
    content: '';
    position: absolute;
    top: 50%;
    /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 1px;
    /* Adjust thickness */
    background: linear-gradient(to bottom right, transparent 50%, red 50%);
    z-index: 1;
}

.single-product-yes {
    padding: 6px 40px;
    background-color: #7ac356;
    color: #fff;
    border: 1px solid #7ac356;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 10px;
}

.single-product-no {
    padding: 6px 40px;
    background-color: #fff;
    color: #7ac356;
    border: 1px solid #7ac356;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 10px;
}

.d-grid {
    display: grid;
    gap: 16px;
}

.border-none {
    border: none !important;
}

.margin-none {
    margin: none !important;
}

.accordion-button:focus,
.accordion-button:hover {
    outline: none !important;
    border: none !important;
}


.customize-add-cart-btn {
    padding: 15px;
    width: 100%;
    background-color: #7ac356;
    color: #fff;
    font-size: 18px;
    border-radius: 15px;
    font-weight: 650;
}



@-webkit-keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 7));
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 7));
    }
}

.slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.slider::before,
.slider::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

.slider .slide {
    height: 100px;
    width: 250px;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
    color: #000 !important;
    background-color: none !important;
}

.accordion-button:not(.collapsed)::after {
    background-color: none !important;
}

.accordion-item {
    border: none !important;
}

.ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ellipsis-one-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}